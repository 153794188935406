import React from 'react';
import Chips from './Chips';
import crypto from '../utils/media/Crypto.png';
import trello from '../utils/media/Trello Clone.png';
import note from '../utils/media/Note Keeper.png';
import pdf from '../utils/media/pdf/Latest_Resume.pdf';

const RightContent = () => {
  const profileSummary = [
    "Software Development Engineer - Frontend with Jollee since 2023, specializing in React, Next.js, and JavaScript. Skilled in building and maintaining critical components for Jollee's frontend, advocating for web accessibility best practices. Collaborative team player experienced in working across diverse projects and with cross-functional teams. Previous experience at Tata Consultancy Services, developing high-quality websites and digital experiences for notable clients. Proficient in React, JavaScript, and adept at problem-solving.",
  ];

  const experience = [
    {
      date: '2023-PRESENT',
      post: 'Software Development Engineer - Frontend @ Jollee',
      desc: 'Build and maintain critical components used to construct Jollee’s frontend, across the whole product. Work closely with cross-functional teams, including developers, designers, and product managers, to implement and advocate for best practices in web accessibility.',
      skills: [
        'React',
        'Next.js',
        'Javascript',
        'HTML',
        'CSS',
        'React Native',
        'REST APIs',
        'Redux',
        'MUI',
      ],
    },
    {
      date: '2021-2022',
      post: 'Developer @ Tata Consultancy Services',
      desc: 'Build, style, and ship high-quality websites, design systems, and digital experiences for a diverse array of projects for clients including KFG(Kourt Food Group), MELSA, MRM and more. ',
      skills: [
        'React',
        'Javascript',
        'HTML',
        'CSS',
        'Git',
        'Web APIs',
        'Problem Solving',
      ],
    },
  ];
  const projs = [
    {
      img: trello,
      head: 'Trello Board Clone',
      desc: 'Clone of trello board which is used to store task under a table, and also enables the user to move the tasks between this tables',
      skills: ['HTML', 'CSS', 'React', 'Redux', 'React-Beautiful-DnD'],
      link: 'https://lighthearted-kashata-107e0f.netlify.app/#/',
    },
    {
      img: crypto,
      head: 'Crypton',
      desc: "It's a website used to monitor changes in crypto currencies",
      skills: ['HTML', 'CSS', 'React'],
      link: 'https://cryptocurrapp.vercel.app/',
    },
    {
      img: note,
      head: 'Note Keeper',
      desc: 'A Note storing website in which the user can create as much notes as he wants which are getting stored in the local storage of the browser',
      skills: ['HTML', 'CSS', 'React', 'Web APIs'],
      link: 'https://notekeeper-beta.vercel.app',
    },
  ];
  return (
    <div className="right-div scroll-hidden">
      <div className="right-div-child flex-gap-10-column" style={{ gap: 20 }}>
        <div id="abt" className="flex-gap-10-column" style={{}}>
          <h2 className="mob-heading poppins-semibold">About</h2>
          {profileSummary?.map((s) => {
            return (
              <p key={s} className="poppins-regular">
                {s}
              </p>
            );
          })}
        </div>

        <div id="exp" className="flex-gap-10-column">
          <h2 className="mob-heading poppins-semibold">Experience</h2>
          <div className="exp-card-stack flex-gap-10-column" style={{}}>
            {experience?.map((e) => {
              return (
                <div className="exp-card" style={{ display: 'flex', gap: 10 }}>
                  <p
                    className="poppins-regular exp-date"
                    style={{ margin: 0, fontSize: 12, color: '#AAAAAA' }}
                  >
                    {e?.date}
                  </p>
                  <div
                    className="exp-card-desc flex-gap-10-column"
                    style={{ gap: 5 }}
                  >
                    <h3 className="poppins-medium" style={{ margin: 0 }}>
                      {e?.post}
                    </h3>
                    <p className="poppins-regular" style={{ fontSize: 14 }}>
                      {e?.desc}
                    </p>
                    <div
                      className="flex-gap-10-align-items-center skills-mob"
                      style={{ gap: 5, flexWrap: 'wrap' }}
                    >
                      {e?.skills?.map((s) => {
                        return <Chips data={s} />;
                      })}
                    </div>
                  </div>
                </div>
              );
            })}

            <a href={pdf} target="_blank" style={{ textDecoration: 'none' }}>
              <div className="flex-gap-10-align-items-center icons" style={{}}>
                <h4 className="poppins-regular" style={{ margin: 0 }}>
                  View Full Resume
                </h4>
                <img src="/media/toprightarrow.svg" width={25} />
              </div>
            </a>
          </div>
        </div>

        <div id="prj" className="flex-gap-10-column">
          <h2 className="mob-heading poppins-semibold">Projects</h2>
          <div className="flex-gap-10-column prj-card-stack" style={{}}>
            {projs?.map((p) => {
              return (
                <a
                  href={p?.link}
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                >
                  <div className="prj-card" style={{ cursor: 'pointer' }}>
                    <img className="desk-image" src={p?.img} width={150} />
                    <div
                      className="exp-card-desc flex-gap-10-column"
                      style={{ gap: 5 }}
                    >
                      <h3 className="poppins-medium" style={{ margin: 0 }}>
                        {p?.head}
                      </h3>
                      <p
                        className="poppins-regular"
                        style={{ fontSize: 14, color: '#94A3B8' }}
                      >
                        {p?.desc}
                      </p>
                      <div
                        className="flex-gap-10-align-items-center skills-mob py-5"
                        style={{ gap: 5, flexWrap: 'wrap' }}
                      >
                        {p?.skills?.map((s) => {
                          return <Chips data={s} />;
                        })}
                      </div>
                    </div>
                    {window.screen.availWidth <= 900 && (
                      <img className="mob-image" src={p?.img} width={200} />
                    )}
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightContent;
