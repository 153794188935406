import React from 'react';

const Chips = ({ data }) => {
  return (
    <div
      className="chips poppins-medium"
      style={{
        padding: '10px',
        color: 'rgb(94 234 212/1)',
        background: 'rgb(94 234 212/0.2)',
        borderRadius: 50,
        fontSize: 12,
      }}
    >
      {data}
    </div>
  );
};

export default Chips;
