import React from 'react';
import git from '../utils/media/git.svg';
import linkedIn from '../utils/media/linkedIn.svg';

const LeftContent = () => {
  const sectionArr = [
    { label: 'About', link: '#abt' },
    { label: 'Experience', link: '#exp' },
    { label: 'Projects', link: '#prj' },
  ];
  return (
    <div className="left-div" style={{}}>
      <div
        className="flex-gap-10-column"
        style={{ color: '#ffffff', justifyContent: 'center' }}
      >
        <h1 className="poppins-bold" style={{ fontSize: 50 }}>
          Mehul Solanki
        </h1>
        <h3 className="poppins-semibold">Frontend Developer</h3>
        <p className="poppins-regular">
          I build pixel-perfect, engaging and accessible digital experience
        </p>

        <div className="toc flex-column-gap-10" style={{ marginTop: 40 }}>
          {sectionArr?.map((sec) => {
            return (
              <div className="section-parent py-5" style={{}}>
                <hr
                  className="section-child transition-all"
                  style={{ marginRight: 10 }}
                />
                <a href={sec?.link} style={{ textDecoration: 'none' }}>
                  <span className="poppins-regular">{sec?.label}</span>
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <div className="icons flex-gap-10-align-items-center">
        <a
          className="contact-icons"
          href="https://github.com/Its-me-mehul"
          target="_blank"
        >
          <img src={git} />
        </a>

        <a
          className="contact-icons"
          href="https://www.linkedin.com/in/mehulsolanki21"
          target="_blank"
        >
          <img src={linkedIn} />
        </a>
      </div>
    </div>
  );
};

export default LeftContent;
