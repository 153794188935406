import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './screens/App';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Error from './screens/Error';

const root = ReactDOM.createRoot(document.getElementById('root'));

const appRouter = createBrowserRouter([
  { path: '/', element: <App />, errorElement: <Error /> },
]);

root.render(<RouterProvider router={appRouter} />);
