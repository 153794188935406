import CursorBack from '../components/CursorBack';
import LeftContent from '../components/LeftContent';
import RightContent from '../components/RightContent';
import '../../src/index.css';

const App = () => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <CursorBack />

      <div
        className="flex-gap-10-align-items-center"
        style={{
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingTop: 20,
          gap: 30,
        }}
      >
        <LeftContent />
        <RightContent />
      </div>
    </div>
  );
};

export default App;
