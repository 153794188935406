import React, { useEffect, useState } from 'react';

const CursorBack = () => {
  const [coordX, setCoordX] = useState();
  const [coordY, setCoordY] = useState();

  const setCoord = (event) => {
    setCoordX(event.clientX);
    setCoordY(event.clientY);
  };
  useEffect(() => {
    window.addEventListener('mousemove', setCoord);

    // return () => {
    //   removeEventListener('mousemove', setCoord);
    // };
  }, []);

  return (
    <div
      className="cursor_back"
      style={{
        background: `radial-gradient(500px at ${coordX}px ${coordY}px, rgba(29, 78, 216, 0.25), transparent 80%)`,
      }}
    ></div>
  );
};

export default CursorBack;
